<template>
  <AppRow permission="GAMES_ADMINISTRATION">
    <template v-slot>
      <ApolloQuery
        :query="require('../graphql/queries/adminBetsGetBetsLimit.gql')"
        :variables="{}"
        :update="mapLimits"
      >
        <template v-slot="{ query }">
          <ApolloMutation
            :mutation="require('../graphql/mutations/adminBetsSetBetsLimit.gql')"
          >
            <template v-slot="{ mutate }">
              <v-btn @click="updateServerLimits(mutate, query)">
                Save
              </v-btn>
            </template>
          </ApolloMutation>
          <v-simple-table>
            <thead>
              <tr>
                <th>Currency</th>
                <th>Сумма выигрышей</th>
                <th>Лимит ставок</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item of limitsList"
                :key="item.purseType"
              >
                <template>
                  <td>
                    {{ item.purseType }}
                  </td>
                  <td>
                    <v-text-field
                      :value="item.betSum"
                      @change="ev => updateField(item.purseType, 'betSum', ev)"
                    />
                  </td>
                  <td>
                    <v-text-field
                      :value="item.betLimit"
                      @change="ev => updateField(item.purseType, 'betLimit', ev)"
                    />
                  </td>
                </template>
              </tr>
            </tbody>
          </v-simple-table>
        </template>
      </ApolloQuery>
    </template>
  </AppRow>
</template>
<script>
import AppRow from '@/components/AppRow.vue';
import adminBetsGetEventForModerate from '@/graphql/queries/adminBetsGetEventForModerate.gql';

export default {
  components: {
    AppRow,
  },
  data() {
    return {
      editedIndex: -1,
      headers: [
        { text: 'Валюта:', value: 'purseType', class: 'white--text text-subtitle-2' },
        { text: 'Сумма выигрыша:', value: 'betSum', class: 'white--text text-subtitle-2' },
        { text: 'Лимит ставки:', value: 'betLimit', class: 'white--text text-subtitle-2' },
      ],
      eventType: 'LIVE',
      gameId: null,
      eventTypeList: [
        { text: 'Лайв', key: 'LIVE' },
        { text: 'Линия', key: 'LINE' },
      ],
      status: null,
      statusList: [
        { text: 'В модерации', key: null },
        { text: 'Активные', key: 'ACTIVE' },
        { text: 'Отмененные', key: 'CANCEL' },
      ],
      betsPageIndex: 1,
      betsItemsPerPage: 5,
      reductionFactor: null,
      dialog: false,
      eventId: null,
      limitsMap: {},
      limitsList: [],
      updateLimits: {},
    };
  },
  methods: {
    refetchQueriesAfterMyMutation() {
      this.dialog = false;
      return [{
        query: adminBetsGetEventForModerate,
        variables: {
          type: this.eventType,
          skip: (this.betsPageIndex - 1) * this.betsItemsPerPage,
          limit: this.betsItemsPerPage,
          status: this.status,
          gameId: this.gameId ? +this.gameId : null,
        },
        fetchPolicy: 'no-cache',
      }];
    },
    showDialog(eventId) {
      this.reductionFactor = null;
      this.eventId = eventId;
      this.dialog = true;
    },
    resetGameIdAndRefetch(query) {
      this.gameId = null;
      this.firstPage(query);
    },
    firstPage(query) {
      setTimeout(() => query.refetch(), 0);
      this.betsPageIndex = 1;
    },
    updateField(purseType, field, value) {
      this.updateLimits[purseType] = {
        ...this.limitsMap[purseType],
        ...this.updateLimits[purseType],
        [field]: Number(value),
      };
    },
    mapLimits(allLimits) {
      if (!allLimits || !allLimits.adminBetsGetBetsLimit) return;
      this.limitsList = allLimits.adminBetsGetBetsLimit;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.limitsList.length; i++) {
        this.limitsMap[this.limitsList[i].purseType] = {
          betLimit: this.limitsList[i].betLimit,
          betSum: this.limitsList[i].betSum,
        };
      }
    },
    async updateServerLimits(mutate, query) {
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const key in this.updateLimits) {
        mutate({
          variables: {
            purseType: key,
            betLimit: this.updateLimits[key].betLimit,
            betSum: this.updateLimits[key].betSum,
          },
        });
      }
      setTimeout(() => {
        query.refetch();
      }, 2000);
    },
  },
};
</script>
